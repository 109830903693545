import * as React from "react";

const SVGHRPackagesBg = (props) => (
	<svg
		width={1440}
		height={524}
		viewBox="0 0 1440 524"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M-26 237.584C229.116 423.131 1165.81 -42.4203 1617 3.16027L1627.5 419.66C1627.5 419.66 578.697 394.061 450.051 483.784C321.405 573.506 -26 483.784 -26 483.784V237.584Z"
			fill="url(#paint0_linear_122_1891)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_122_1891"
				x1={130}
				y1={249}
				x2={1615.5}
				y2={351.5}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFD337" />
				<stop offset={1} stopColor="#FFD337" stopOpacity={0} />
			</linearGradient>
		</defs>
	</svg>
);

export default SVGHRPackagesBg;
