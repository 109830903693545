import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Link from "./link";

const PackageCard = ({ price, title, body, discountSmall, noDiscount }) => {
	return (
		<Col md={6} xxl={3}>
			<div className="card-spin position-relative">
				<Card
					style={{ borderRadius: "20px" }}
					className="border-0 review-card text-black  card-spin__side card-spin__side--front   my-4 mx-0"
				>
					<Card.Header
						style={{
							borderRadius: "20px 20px 0px 0px",
						}}
						className="bg-primary text-center border-bottom border-5 border-secondary"
					>
						<Card.Title className="fs-5 fw-bold">{title}</Card.Title>
					</Card.Header>
					<Card.Body>
						<Row className="package-card">
							<Col>
								<Card.Text>
									<p>{body}</p>
								</Card.Text>
							</Col>
						</Row>
						<Row style={{ height: "140px" }}>
							<Col className=" text-center">
								<hr className="w-80  mx-auto" />
								<div className="h-100 d-flex align-items-center justify-content-center">
									{price}
								</div>
							</Col>
						</Row>
					</Card.Body>

					<Card.Footer
						style={{
							borderRadius: "0px 0px 20px 20px",
						}}
						className="text-center bg-bgGrey py-1"
					>
						<p
							className={`${
								noDiscount !== true ? "text-white" : "text-bgGrey"
							} py-0 my-0 ${discountSmall ? "d-none" : ""}`}
						>
							10% discount to charities
						</p>
						<p
							className={` ${
								noDiscount !== true ? "text-white" : "text-bgGrey"
							} py-0 my-0 ${discountSmall ? "" : "d-none"}`}
						>
							10% discount to charities
						</p>
					</Card.Footer>
				</Card>
				<Card
					style={{
						borderRadius: "20px",
						paddingRight: "12px",

						background: "none",
					}}
					className="border-0 text-center  mt-0   card-spin__side card-spin__side--back position-absolute h-100 w-100 top-0  my-4 "
				>
					<Card.Body
						style={{
							borderRadius: "20px",
						}}
						className="d-flex bg-bgGrey align-items-center justify-content-center"
					>
						<Row className="justify-content-center">
							<Col>
								<h2 className=" text-white d-block mb-4">
									Ready to speak to us?
								</h2>

								<Button
									as={Link}
									style={{ zIndex: 2 }}
									variant="outline-banner"
									className=" w-100 w-md-auto fs-5 pb-2 position-relative cta-btn"
									to="/contact-us"
								>
									Contact Us
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</div>
		</Col>
	);
};

export default PackageCard;
